





























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { State, Action } from "vuex-class";

type Headers = {
  text: string;
  align?: string;
  value: string;
  sortable?: boolean;
};
type Mantenedor = {
  id?: number;
  nombre: string;
  empresa: string;
  email: string;
  telefono: string;
};

@Component
export default class MantenedoresTabla extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.mantenedores)
  mantenedores!: Array<Mantenedor>;

  @Action actualizarMantenedor!: Function;
  @Action eliminarMantenedor!: Function;
  public headers: Array<Headers> = [
    {
      text: "NOMBRE",
      align: "start",
      sortable: true,
      value: "nombre"
    },
    {
      text: "EMPRESA",
      align: "start",
      sortable: true,
      value: "empresa"
    },
    {
      text: "EMAIL",
      align: "start",
      sortable: true,
      value: "email"
    },
    {
      text: "TELÉFONO",
      align: "start",
      sortable: true,
      value: "telefono"
    },
    { text: "ACCIONES", value: "actions", sortable: false }
  ];
  public search = "";

  public dialog = false;
  public dialogDelete = false;

  public desserts: Array<any> = [];
  public editedIndex = -1;
  public editedItem: Mantenedor = {
    nombre: "",
    empresa: "",
    email: "",
    telefono: ""
  };
  public defaultItem: Mantenedor = {
    nombre: "",
    empresa: "",
    email: "",
    telefono: ""
  };

  get formTitle(): string {
    return this.editedIndex === -1 ? "Agregar" : "Editar Mantenedor";
  }
  @Watch("dialog")
  dialogName(val: any) {
    val || this.close();
  }
  @Watch("dialogDelete")
  dialogDeleteName(val: any) {
    val || this.closeDelete();
  }

  private editItem(item: any) {
    const copyMantenedores = this.mantenedores;
    this.editedIndex = copyMantenedores.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }
  private deleteItem(item: any) {
    const copyMantenedores = this.mantenedores;
    this.editedIndex = copyMantenedores.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }
  private deleteItemConfirm() {
    const copyMantenedores = this.mantenedores;
    copyMantenedores.splice(this.editedIndex, 1);
    this.eliminarMantenedor({
      idCondo: this.condominio.id,
      idMantenedor: this.editedItem.id
    });
    this.closeDelete();
  }
  private close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  private closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  public save() {
    const copyMantenedores = this.mantenedores;
    if (this.editedIndex > -1) {
      Object.assign(copyMantenedores[this.editedIndex], this.editedItem);
      console.info(this.editedItem);
      this.actualizarMantenedor({
        idCondo: this.condominio.id,
        idMantenedor: this.editedItem.id,
        data: this.editedItem
      });
    } else {
      // copyMantenedores.push(this.editedItem);
      // console.info(this.editedItem);
      // this.registrarGasto({ idEvento: this.id, data: this.editedItem });
    }
    this.close();
  }
}
