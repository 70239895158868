





































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required, email, minLength } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class MantenedoresForm extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.mantenedores) mantenedores!: Array<any>;
  @State(({ appState }) => appState.mantenedor) mantenedor!: any;
  @Action registrarMantenedor!: Function;
  @Validate({ required, minLength: minLength(5) }) nombre = null;
  @Validate({ required, minLength: minLength(5) }) telefono = null;
  @Validate({ required, email, minLength: minLength(5) }) email = null;
  @Validate({ required, minLength: minLength(5) }) empresa = null;

  //REGISTRAR NUEVO MANTENEDOR
  private agregarMantenedor(): void {
    const mantenedor = {
      nombre: this.nombre,
      email: this.email,
      telefono: this.telefono,
      empresa: this.empresa
    };
    console.info(mantenedor);
    this.registrarMantenedor({ condoId: this.condominio.id, data: mantenedor });
    this.clearForm();
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.email = null;
    this.nombre = null;
    this.empresa = null;
    this.telefono = null;
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.nombre ||
      !this.empresa ||
      !this.telefono ||
      !this.$v.email.email
      ? true
      : false;
  }

  //VALIDACIONES
  get nombreErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    !this.$v.nombre.required && errors.push("El campo es requerido");
    !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 digitos");
    return errors;
  }
  get empresaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresa.$dirty) return errors;
    !this.$v.empresa.required && errors.push("El campo es requerido");
    !this.$v.empresa.minLength && errors.push("Debe contener minimo 5 digitos");
    return errors;
  }
  get telefonoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    !this.$v.telefono.required && errors.push("El campo es requerido");
    !this.$v.telefono.minLength &&
      errors.push("Debe contener minimo 5 digitos");
    return errors;
  }
  get emailErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.required && errors.push("El campo es requerido");
    !this.$v.email.email && errors.push("El email debe ser valido");
    return errors;
  }
}
