



















import { Component, Vue } from "vue-property-decorator";

import MantenedoresTabla from "@/components/Mantenedores/MantenedoresTabla.vue";
import MantenedoresForm from "@/components/Mantenedores/MantenedoresForm.vue";

@Component({
  components: {
    MantenedoresTabla,
    MantenedoresForm
  }
})
export default class Mantenedores extends Vue {}
